import { Reducer, combineReducers } from 'redux';
import { AccessTokenState, LanguageState, LoginState, configReducer, langReducer, loginReducer } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer';
import { baseApi } from './features/app/api/baseApi';
import { supplierDataApi } from './features/app/api/deliverySchedule/supplierDataApi';
import { courierOrderIntentApi } from './features/app/api/dispatchProposal/courierOrderIntent/courierOrderIntentApi';
import { emailNotificationApi } from './features/app/api/emailNotification/emailNotificationApi';
import { ediFilesApi } from './features/app/api/file/ediFilesApi';
import { loadingLocationApi } from './features/app/api/loadingLocation/loadingLocationApi';
import { placeOfDischargeMasterDataApi } from './features/app/api/masterData/placesOfDischargeMasterDataApi';
import { packagingTemplateApi } from './features/app/api/packagingTemplate/packagingTemplateApi';
import { shipmentApi } from './features/app/api/shipment/shipmentApi';
import { shippersApi } from './features/app/api/shippers/shippersApi';
import { transportOrderApi } from './features/app/api/transportOrder/transportOrdersApi';
import { transportOrderIntentApi } from './features/app/api/transportOrderIntent/transportOrderIntentsApi';
import { appSlice } from './features/app/reducers/App.reducers';
import { courierOrderIntentSlice } from './features/app/reducers/courierOrderIntent/CourierOrderIntentReducer';
import { deliverySchedulesSlice } from './features/app/reducers/deliverySchedules/DeliverySchedules.reducer';
import { dispatchProposalsSlice } from './features/app/reducers/dispatchProposal/DispatchProposalsReducer';
import { masterDataSlice } from './features/app/reducers/masterData/MasterData.reducer';
import { packagingMaterialSlice } from './features/app/reducers/packaging/PackagingMaterial.reducer';
import { shipmentDocumentsSlice } from './features/app/reducers/shipmentDocuments/ShipmentDocuments.reducer';
import { packagingSlice } from './features/app/reducers/shipments/Packaging.reducer';
import { shipmentsSlice } from './features/app/reducers/shipments/Shipments.reducer';
import { shippersSlice } from './features/app/reducers/shippers/ShippersReducer';
import { shipmentSuggestionsSlice } from './features/app/reducers/suggestions/ShipmentSuggestions.reducer';
import { transportOrdersSlice } from './features/app/reducers/transportOrders/TransportOrders.reducer';
import { uiSlice } from './features/app/reducers/ui/UI.reducer';

const webEdiReducer = combineReducers({
    deliverySchedules: deliverySchedulesSlice.reducer,
    shipments: shipmentsSlice.reducer,
    dispatchProposals: dispatchProposalsSlice.reducer,
    courierOrderIntent: courierOrderIntentSlice.reducer,
    shippers: shippersSlice.reducer,
    transportOrders: transportOrdersSlice.reducer,
    shipmentDocuments: shipmentDocumentsSlice.reducer,
    shipmentSuggestions: shipmentSuggestionsSlice.reducer,
    masterData: masterDataSlice.reducer,
    packaging: packagingSlice.reducer,
    packagingMaterial: packagingMaterialSlice.reducer,
    ui: uiSlice.reducer,
});

export const rootReducer = () =>
    combineReducers({
        config: configReducer,
        lang: langReducer as Reducer<LanguageState | undefined>,
        login: loginReducer as Reducer<LoginState | undefined>,
        app: appSlice.reducer,
        tokenHandling: tokenHandlingReducer as Reducer<AccessTokenState | undefined>,
        baseApi: baseApi.reducer,
        transportOrderIntentApi: transportOrderIntentApi.reducer,
        transportOrderApi: transportOrderApi.reducer,
        supplierDataApi: supplierDataApi.reducer,
        placeOfDischargeMasterDataApi: placeOfDischargeMasterDataApi.reducer,
        courierOrderIntentApi: courierOrderIntentApi.reducer,
        shippersApi: shippersApi.reducer,
        shipmentApi: shipmentApi.reducer,
        ediFilesApi: ediFilesApi.reducer,
        packagingTemplateApi: packagingTemplateApi.reducer,
        loadingLocationApi: loadingLocationApi.reducer,
        emailNotificationApi: emailNotificationApi.reducer,
        webEdi: webEdiReducer,
    });

const state = rootReducer();
export type State = ReturnType<typeof state>;
