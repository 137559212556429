import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetConsignorsQuery } from '../../../api/triangularProcess/triangularProcessApi';
import { useGetCompanyInfoQuery } from '../../../api/userInfo/userInfoApi';
import { Consignor } from '../../../domain/triangularProcess.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { useManipulatePath } from '../../../hooks/useManipulatePath';
import { LoadingIndicator } from '../../common/LoadingIndicator';

export const DUNS_SELECTION_RADIO_BUTTONS_TEST_ID = 'DUNS_SELECTION_RADIO_BUTTONS_TEST_ID';

export const TriangularProcessDunsSelector = (props: {
    setShowPopover?: Dispatch<SetStateAction<boolean>>;
}) => {
    const [selectedDuns, setSelectedDuns] = useState(useDunsNumberFromPath());
    const [isInitialized, setIsInitialized] = useState(false);
    const { data: consignors, isLoading } = useGetConsignorsQuery();
    const currentDunsNumber = useDunsNumberFromPath();
    const { switchDunsNumber } = useManipulatePath();

    useEffect(() => {
        if (isInitialized) {
            if (selectedDuns !== undefined && selectedDuns.trim().length > 0) {
                props.setShowPopover?.(false);
                switchDunsNumber(selectedDuns, currentDunsNumber);
            }
        }
    }, [selectedDuns, isInitialized, props.setShowPopover, switchDunsNumber, currentDunsNumber]);

    interface RadioButtonListProps {
        consignors: Consignor[] | undefined;
    }

    interface DunsNumberOptionProps {
        dunsNumber: string;
    }

    const RadioButtonHeader = (props: RadioButtonListProps): ReactElement => {
        const { consignors } = props;
        const formattedMessageId =
            consignors === undefined || consignors.length === 0
                ? 'webedi.triangularProcess.consignor.noNunsNumbersAvailable'
                : 'webedi.triangularProcess.consignor.dunsNumbers';
        return (
            <label>
                <FormattedMessage id={`${formattedMessageId}`} />
            </label>
        );
    };

    const RadioButtonList = (props: RadioButtonListProps): ReactElement => {
        const { consignors } = props;
        if (consignors === undefined) {
            return <></>;
        }
        return (
            <div data-testid={DUNS_SELECTION_RADIO_BUTTONS_TEST_ID}>
                {consignors.map((consignor) => {
                    const dunsNumber = consignor.dunsNumber;
                    return <DunsNumberOption dunsNumber={dunsNumber} key={dunsNumber} />;
                })}
            </div>
        );
    };

    const DunsNumberOption = (props: DunsNumberOptionProps) => {
        const { dunsNumber } = props;
        const companyInfoResult = useGetCompanyInfoQuery(dunsNumber ?? '');
        const companyNameString = companyInfoResult.isSuccess ? (
            <span className={'text-color-dark text-size-12'}>{`(${companyInfoResult.data.companyName})`}</span>
        ) : (
            ''
        );
        return (
            <RadioButton
                key={dunsNumber}
                name={dunsNumber}
                onChange={() => {
                    setSelectedDuns(dunsNumber);
                    setIsInitialized(true);
                }}
                checked={selectedDuns === dunsNumber}
            >
                {dunsNumber} {companyNameString}
            </RadioButton>
        );
    };

    if (isLoading) {
        return <LoadingIndicator />;
    } else {
        return (
            <>
                <RadioButtonHeader consignors={consignors} />
                <RadioButtonList consignors={consignors} />
            </>
        );
    }
};
