import { v4 as uuid } from 'uuid';
import { DispatchProposal, DispatchProposalProblem } from '../../../domain/dispatchProposal.types';
import {
    DispatchProposalProblemBanner,
    DispatchProposalProblemCombinedErrorBanner,
} from './DispatchProposalProblemBanner';
import { articleQuantityDiscrepancyProblemHandler } from './bannerMappers/ArticleQuantityDiscrepancyProblemBannerMappers';
import { dispatchProposalFreightForwarderChangedProblemHandler } from './bannerMappers/DispatchProposalFreightForwarderChangedProblemBannerMappers';
import { dispatchProposalNotCompletelyPackagedProblemHandler } from './bannerMappers/DispatchProposalNotCompletelyPackagedProblemBannerMappers';
import { missingArticleMasterDataProblemHandler } from './bannerMappers/MissingArticleMasterDataProblemBannerMappers';
import { missingLoadingLocationProblemHandler } from './bannerMappers/MissingLoadingLocationProblemBannerMappers';
import { missingPackagingHeightProblemHandler } from './bannerMappers/MissingPackagingHeightProblemBannerMappers';
import { tooManyPackagingItemsInOneLoadingUnitProblemHandler } from './bannerMappers/TooManyPackagingItemsInOneLoadingUnitProblemBannerMappers';

export interface DispatchProposalProblemBannersProps {
    problems: DispatchProposalProblem[];
    dispatchProposals: DispatchProposal[];
}

export const DispatchProposalProblemBanners = (props: DispatchProposalProblemBannersProps) => {
    const warningProcessors = [missingArticleMasterDataProblemHandler, articleQuantityDiscrepancyProblemHandler];
    const errorProcessors = [
        missingLoadingLocationProblemHandler,
        missingPackagingHeightProblemHandler,
        dispatchProposalNotCompletelyPackagedProblemHandler,
        tooManyPackagingItemsInOneLoadingUnitProblemHandler,
    ];
    const infoProcessors = [dispatchProposalFreightForwarderChangedProblemHandler];

    return (
        <>
            {infoProcessors.map((callback) => (
                <DispatchProposalProblemBanner
                    key={uuid()}
                    problems={props.problems}
                    generateAndFilterProblems={callback}
                    dispatchProposals={props.dispatchProposals}
                />
            ))}
            {warningProcessors.map((callback) => (
                <DispatchProposalProblemBanner
                    key={uuid()}
                    problems={props.problems}
                    generateAndFilterProblems={callback}
                    dispatchProposals={props.dispatchProposals}
                />
            ))}
            <DispatchProposalProblemCombinedErrorBanner
                problems={props.problems}
                errorProcessors={errorProcessors}
                dispatchProposals={props.dispatchProposals}
            />
        </>
    );
};
