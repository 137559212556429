import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreShipmentSuggestions } from '../../actions/suggestions/ShipmentSuggestions.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { shipmentSuggestionsSlice } from '../../reducers/suggestions/ShipmentSuggestions.reducer';
import {
    getShipmentSuggestions,
    getShipmentSuggestionsQuery,
    isLoadingShipmentSuggestions,
} from '../../selectors/suggestions/ShipmentSuggestions.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { ShipmentSuggestion } from './ShipmentSuggestion';

export const ShipmentSuggestionsList = (props: { allSuggestionExpanderOpen: boolean }) => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();

    const shipmentSuggestions = useAppSelector(getShipmentSuggestions);
    const query = useAppSelector(getShipmentSuggestionsQuery);
    const isLoading = useAppSelector(isLoadingShipmentSuggestions);

    // biome-ignore lint/correctness/useExhaustiveDependencies: including query causes infinite loop
    useEffect(() => {
        dispatch(shipmentSuggestionsSlice.actions.updateQuery({ ...query, dunsNumber }));
        dispatch(fetchAndStoreShipmentSuggestions());
    }, [dunsNumber, dispatch]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (shipmentSuggestions.length === 0) {
        return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
    }

    return (
        <div data-testid={SHIPMENT_SUGGESTIONS_LIST_TEST_ID}>
            {shipmentSuggestions.map((suggestion) => (
                <ShipmentSuggestion
                    shipmentSuggestion={suggestion}
                    key={`shipmentSuggestion-${suggestion.placeOfDischarge.id}_${suggestion.requestedDeliveryDate}`}
                    allShipmentSuggestionExpanderOpen={props.allSuggestionExpanderOpen}
                />
            ))}
        </div>
    );
};

export const SHIPMENT_SUGGESTIONS_LIST_TEST_ID = 'SHIPMENT_SUGGESTIONS_LIST_TEST_ID';
