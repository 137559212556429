import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Divider from '@rio-cloud/rio-uikit/Divider';
import FeedbackReactions from '@rio-cloud/rio-uikit/FeedbackReactions';
import Page from '@rio-cloud/rio-uikit/Page';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import { MD5 } from 'object-hash';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserProfileType } from '../../../../configuration';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getUserSelectedLocale } from '../../selectors/lang/Lang.selector';
import { getUserProfile } from '../../selectors/login/Login.selector';

const hashUserEmail = (email: string) => `${MD5(email)}_upsllng`;

const delay = (action: () => void, timeout = 1000) => {
    setTimeout(() => {
        action();
    }, timeout);
};

const FOUR_WEEKS = 1000 * 60 * 60 * 24 * 7 * 4;
const NEVER = Number.MAX_SAFE_INTEGER / 2;

export const UpsellingBottomSheet = () => {
    const userProfile = useAppSelector(getUserProfile);
    const userSelectedLocale = useAppSelector(getUserSelectedLocale);
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    useEffect(() => {
        delay(() => setIsPopupVisible(initPopup()));
    }, []);

    useEffectOnce(() => {
        const listenerCallback = (event: Event) => onFormSubmitted(event);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    });

    const onThumbUpClick = () => {
        setIsModalVisible(true);
    };

    const onThumbDownClick = () => {
        setIsPopupVisible(false);
        setIsModalVisible(false);
        finishUpsellingWithExpiration(userProfile, FOUR_WEEKS);
    };

    const onModalClosed = () => {
        setIsModalVisible(false);
    };

    const onFormSubmitted = (event: any) => {
        const { type = '' } = event.data;
        if (type === 'LEAD_FORM_SUBMITTED') {
            setIsPopupVisible(false);
            finishUpsellingWithExpiration(userProfile, NEVER);
        }
    };

    const initPopup = () => {
        return (): boolean => {
            const key = hashUserEmail(userProfile?.email || '');
            const item = localStorage.getItem(key);

            if (item == null) {
                return true;
            }
            if (Number(item) <= Date.now()) {
                localStorage.removeItem(key);
                return true;
            }
            return false;
        };
    };

    const finishUpsellingWithExpiration = (userProfile: UserProfileType | null | undefined, expirationInMs: number) => {
        if (userProfile === null || userProfile === undefined || userProfile.email === undefined) {
            return;
        }
        localStorage.setItem(hashUserEmail(userProfile.email), (Date.now() + expirationInMs).toString());
    };

    const topic = 'WebSCM';
    const url = [
        'https://cloud.news.rio.cloud/lead_generation?',
        `topic=${topic}`,
        `&locale=${userSelectedLocale}`,
        `&sourceUrl=${document.URL}`,
        `&hideNewsletter=true`,
    ].join('');

    const popup = (
        <Page
            width={330}
            orientation={'landscape'}
            className='display-flex flex-column position-fixed right-25 bottom-25 z-index-10'
        >
            <div className='position-absolute top-10 right-10'>
                <Button bsStyle={Button.MUTED} iconOnly onClick={onThumbDownClick} iconName='rioglyph-remove' />
            </div>
            <div className='display-flex justify-content-start margin-bottom-10 align-items-start gap-10'>
                <div className='width-90'>
                    <img
                        className='display-block width-100pct'
                        src={'https://cdn.rio.cloud/svg/common/ico_rio_colored.svg'}
                        alt={'RIO'}
                    />
                </div>
                <div className='text-size-h4 text-uppercase text-medium padding-left-10 justify-content-center text-wrap-balance'>
                    <FormattedMessage id={'webedi.upselling.popup.title'} />
                </div>
            </div>
            <div className='margin-auto text-size-18 text-thin'>
                <FormattedMessage id={'webedi.upselling.popup.text'} />
            </div>
            <div className='max-width-300 margin-auto margin-bottom-5 display-flex justify-content-center text-size-16 text-wrap-balance'>
                <FeedbackReactions
                    onVoteUp={onThumbUpClick}
                    onVoteDown={onThumbDownClick}
                    labelUp={<FormattedMessage id={'webedi.upselling.popup.thumbsup'} />}
                    labelDown={<FormattedMessage id={'webedi.upselling.popup.thumbsdown'} />}
                    animated
                />
            </div>
        </Page>
    );

    const modal = (
        <Dialog
            show={isModalVisible}
            showCloseButton
            onEsc={onModalClosed}
            title={
                <span>
                    <FormattedMessage id={'webedi.upselling.modal.header'} />
                </span>
            }
            body={
                <div className='padding-left-25 padding-right-25'>
                    <div className='text-size-18 text-bold margin-left-25 margin-right-25 padding-left-50 padding-right-50 padding-top-25 padding-bottom-25 text-center'>
                        <FormattedMessage id={'webedi.upselling.modal.title'} />
                    </div>
                    <div className='display-flex justify-content-center'>
                        <img
                            className='width-80'
                            src={'https://cdn.rio.cloud/svg/common/ico_rio_colored.svg'}
                            alt={'RIO'}
                        />
                    </div>
                    <Divider spacing={0} className='margin-top-25' />
                    <p className='white-space-pre-line text-size-14 text-justify'>
                        <FormattedMessage
                            id={'webedi.upselling.modal.text'}
                            values={{
                                a: (chunks: ReactNode) => {
                                    return (
                                        <strong>
                                            <a
                                                href={`https://outlook.office365.com/book/supplierintegration1@rio.cloud/s/4O4yT2Z7g0ysX7VFcEqL8w2`}
                                                target={'_blank'}
                                                onClick={() => finishUpsellingWithExpiration(userProfile, NEVER)}
                                                rel={'noreferrer'}
                                            >
                                                {chunks}
                                            </a>
                                        </strong>
                                    );
                                },
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                            }}
                        />
                    </p>
                    <div className='margin-top-25 padding-top-10 margin-bottom--10 min-height-600'>
                        {
                            <iframe
                                src={url}
                                style={{
                                    minWidth: '100%',
                                    border: '0',
                                    minHeight: '250px',
                                    marginBottom: '20px',
                                }}
                            />
                        }
                    </div>
                </div>
            }
        />
    );
    return (
        <>
            {isPopupVisible && popup}
            {modal}
        </>
    );
};
