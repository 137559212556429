import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    DispatchProposalProblem,
    PackagingSummary,
    isTooManyPackagingItemsInOneLoadingUnitProblem,
} from '../../../domain/dispatchProposal.types';
import { Tooltip } from '../../common/Tooltip';

export const INNER_PACKAGING_SUMMARY_TOOLTIP_TEST_ID = 'INNER_PACKAGING_SUMMARIES_TOOLTIP_TEST_ID';
export const OUTER_PACKAGING_SUMMARY_TOOLTIP_TEST_ID = 'OUTER_PACKAGING_SUMMARIES_TOOLTIP_TEST_ID';

const renderPackagingSummary = (summary: PackagingSummary) => (
    <FormattedMessage
        id='webedi.dispatchProposals.planDispatchProposal.body.table.packagingSummary'
        values={{
            amount: summary.count,
            type: summary.type,
            strong: (chunks: ReactNode) => <strong className='padding-left-3'>{chunks}</strong>,
        }}
    />
);

const renderTooManyPackagingItemsInOneLoadingUnit = (type: string, count: number) => (
    <div key={`${type}-${count}`} className='ellipsis-1 text-color-danger'>
        <span className='align-items-center'>
            <span className='text-color-danger rioglyph rioglyph-error-sign text-size-18 margin-right-3' />
            <span>
                <FormattedMessage id='webedi.dispatchProposals.planDispatchProposal.body.table.tooManyPackagingItemsInOneLoadingUnit' />
            </span>
        </span>
    </div>
);

export type InnerPackagingSummaryProps = {
    problems: DispatchProposalProblem[];
    packagingSummary: PackagingSummary[];
};

export const InnerPackagingSummary = ({ problems, packagingSummary }: InnerPackagingSummaryProps) => {
    const tooltipContents = (
        <span data-testid={INNER_PACKAGING_SUMMARY_TOOLTIP_TEST_ID}>
            {packagingSummary.map((summary) => (
                <div key={`${summary.type}-${summary.count}`}>{renderPackagingSummary(summary)}</div>
            ))}
        </span>
    );

    return (
        <Tooltip text={tooltipContents} placement='bottom-start'>
            <div>
                {problems.some(isTooManyPackagingItemsInOneLoadingUnitProblem)
                    ? renderTooManyPackagingItemsInOneLoadingUnit(packagingSummary[0].type, packagingSummary[0].count)
                    : packagingSummary.slice(0, 2).map((summary, index) => (
                          <div className='ellipsis-1' key={`${summary.type}-${summary.count}`}>
                              <span className='align-items-center'>
                                  {renderPackagingSummary(summary)}
                                  {index === 1 && packagingSummary.length > 2 && <span>, …</span>}
                              </span>
                          </div>
                      ))}
            </div>
        </Tooltip>
    );
};

export type OuterPackagingSummaryProps = {
    packagingSummary: PackagingSummary;
};

export const OuterPackagingSummary = ({ packagingSummary }: OuterPackagingSummaryProps) => {
    const summary = renderPackagingSummary(packagingSummary);
    const tooltipContents = (
        <span data-testid={OUTER_PACKAGING_SUMMARY_TOOLTIP_TEST_ID}>
            <div key={`${packagingSummary.type}-${packagingSummary.count}`}>{summary}</div>
        </span>
    );

    return (
        <Tooltip text={tooltipContents} placement='bottom-start'>
            <div className='ellipsis-1' key={`${packagingSummary.type}-${packagingSummary.count}`}>
                <span className='align-items-center'>
                    <span className='rioglyph rioglyph-parcel text-size-18 margin-right-3' />
                    {summary}
                </span>
            </div>
        </Tooltip>
    );
};
