import { CompanyInfo, UserInfo } from '../../domain/userInfo.types';
import { decodeJson } from '../apiUtils';
import { baseApi } from '../baseApi';
import { companyInfoCodec, userInfoCodec } from './userInfo.types';

const transformUserInfoResponse = (response: unknown): UserInfo => {
    const decoded = decodeJson(userInfoCodec)(response);
    return { dunsNumber: decoded.duns_number };
};

const transformCompanyInfoResponse = (response: unknown): CompanyInfo => {
    const decoded = decodeJson(companyInfoCodec)(response);
    return { companyName: decoded.company_name };
};

export const userInfoApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserInfo: builder.query<UserInfo, string>({
            query: (userId) => ({
                url: `/user-info`,
                params: { user_id: userId },
            }),
            transformResponse: (response: unknown) => transformUserInfoResponse(response),
        }),
        getCompanyInfo: builder.query<CompanyInfo, string>({
            query: (dunsNumber) => ({
                url: `/shippers/${dunsNumber}`,
            }),
            transformResponse: (response: unknown) => transformCompanyInfoResponse(response),
        }),
    }),
});

export const { useLazyGetUserInfoQuery, useGetCompanyInfoQuery } = userInfoApi;
