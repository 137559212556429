import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import Divider from '@rio-cloud/rio-uikit/Divider';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTriangularProcessFeatures } from '../../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../../configuration/featureToggle/utils';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isSupplier } from '../../../../../configuration/tokenHandling/selectors';
import { useLoggedInUserDunsNumber } from '../../../hooks/Routing.hooks';

import { TriangularProcessDunsSelector } from '../triangularProcess/TriangularProcessDunsSelector';
import { RioCompanyName } from './RioCompanyName';
import { RioSelectedDunsNumber } from './RioSelectedDunsNumber';

export const COMPANY_INFO_TEST_ID = 'COMPANY_INFO_TEST_ID';
export const COMPANY_INFO_ICON_TEST_ID = 'COMPANY_INFO_ICON_TEST_ID';

export const RioCompanyInfo = () => {
    const isSupplierUser = useAppSelector(isSupplier);
    const loggedInUserDuns = useLoggedInUserDunsNumber();
    const isTriangularProcessEnabled = getValueOrDefaultWhenLoading(useTriangularProcessFeatures(loggedInUserDuns));
    return (
        <ActionBarItem id={'companyInfo'} popoverWidth={300} data-testid={COMPANY_INFO_TEST_ID}>
            <ActionBarItem.Icon>
                <span data-testid={COMPANY_INFO_ICON_TEST_ID} className={'icon rioglyph rioglyph rioglyph-factory'} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover>
                <div>
                    <FormattedMessage
                        id={'webedi.companyInfo.description'}
                        values={{ strong: (chunks: ReactNode) => <strong>{chunks}</strong> }}
                    />
                </div>
                <RioSelectedDunsNumber key={'selectedDunsNumber'} />
                <RioCompanyName />
                {isSupplierUser && isTriangularProcessEnabled && (
                    <div>
                        <Divider />
                        <TriangularProcessDunsSelector />
                    </div>
                )}
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};
