export enum FieldErrorType {
    REQUIRED = 'webedi.form.error.requiredField',
    TOO_LONG = 'webedi.form.error.tooLong',
    TOO_SHORT = 'webedi.form.error.tooShort',
    EXACT_LENGTH = 'webedi.form.error.exactlyXCharacters',
    NON_ALPHANUMERIC = 'webedi.form.error.nonAlphaNumeric',
    NON_POSITIVE_NUMBER = 'webedi.form.error.nonPositiveNumber',
    TOO_BIG = 'webedi.form.error.tooBigNumber',
    CONTAINS_INVALID_CHARACTERS = 'webedi.form.error.containsInvalidCharacters',
    FORBIDDEN_VALUE = 'webedi.form.error.forbiddenValue',
    BLANK = 'webedi.form.error.blank',
    DEPENDS_ON_NUMBER_LABEL_COPIES = 'webedi.shipmentDocuments.settings.plantSpecificSettings.ValidationMessage',
    LOADING_POINT_ID_ALREADY_EXISTS = 'webedi.loadingLocation.loadingLocationId.alreadyExists.ValidationMessage',
}

export type FormErrors<T, E = never> = Partial<{
    [K in keyof T]: T[K] extends boolean | string | number | undefined ? E | FieldErrorType : FormErrors<T[K], E>;
}>;
