import { useGetCompanyInfoQuery } from '../../../api/userInfo/userInfoApi';
import { useLoggedInUserDunsNumber } from '../../../hooks/Routing.hooks';

export const MENU_COMPANY_NAME = 'MENU_COMPANY_NAME';

export const RioCompanyName = () => {
    const dunsNumber = useLoggedInUserDunsNumber();
    const companyInfoResult = useGetCompanyInfoQuery(dunsNumber ?? '');
    const companyName = companyInfoResult.isSuccess ? companyInfoResult.data.companyName : undefined;

    return (
        <>
            {dunsNumber && companyName && (
                <div className={'text-left'} data-testid={MENU_COMPANY_NAME}>
                    <i>{companyName}</i>
                </div>
            )}
        </>
    );
};
