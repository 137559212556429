import { Consignor } from '../../domain/triangularProcess.types';
import { decodeJson } from '../apiUtils';
import { baseApi } from '../baseApi';
import { getConsignorsResponseCodec } from './triangularProcess.types';

const transformGetConsignorsResponse = (response: unknown): Consignor[] => {
    const decoded = decodeJson(getConsignorsResponseCodec)(response);
    return decoded.items.map((item) => ({ dunsNumber: item.duns_number }));
};

export const triangularProcessApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getConsignors: builder.query<Consignor[], void>({
            query: () => ({
                url: '/shippers/consignors',
            }),
            transformResponse: (response: unknown) => transformGetConsignorsResponse(response),
        }),
    }),
});

export const { useGetConsignorsQuery } = triangularProcessApi;
