import { useLocation, useNavigate } from 'react-router';
import { Routing } from '../components/routing/Routes';

export const useManipulatePath = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isOnShipmentsDetailView = (): boolean => {
        if (!location.pathname.includes(Routing.shipments)) {
            return false;
        }
        return location.pathname.indexOf(Routing.shipments) + Routing.shipments.length !== location.pathname.length;
    };

    const isOnExportedShipmentsDetailView = (): boolean => {
        if (!location.pathname.includes(Routing.exportedShipments)) {
            return false;
        }
        return (
            location.pathname.indexOf(Routing.exportedShipments) + Routing.exportedShipments.length !==
            location.pathname.length
        );
    };

    const isOnMetadataPage = (): boolean =>
        location.pathname.includes(Routing.deliverySchedules) && location.pathname.endsWith(Routing.metadata);

    const switchDunsNumber = (newDunsNumber: string, currentDunsNumber?: string): void => {
        if (location.pathname.startsWith(Routing.webScm) && currentDunsNumber) {
            if (isOnShipmentsDetailView()) {
                const updatedUrl = `${Routing.webScm}/${newDunsNumber}${Routing.shipments}`;
                navigate(updatedUrl);
            } else if (isOnExportedShipmentsDetailView()) {
                const updatedUrl = `${Routing.webScm}/${newDunsNumber}${Routing.exportedShipments}`;
                navigate(updatedUrl);
            } else if (isOnMetadataPage()) {
                const updatedUrl = `${Routing.webScm}/${newDunsNumber}${Routing.deliverySchedules}`;
                navigate(updatedUrl);
            } else {
                const updatedUrl = location.pathname.replace(
                    `${Routing.webScm}/${currentDunsNumber}`,
                    `${Routing.webScm}/${newDunsNumber}`,
                );
                navigate(updatedUrl);
            }
        } else {
            navigate(`${Routing.webScm}${Routing.dunsSelection}`);
        }
    };

    return { switchDunsNumber };
};
