import getOr from 'lodash/fp/getOr';
import { RIOIdToken, VWIdToken, isRioIdToken, isVWIdToken } from './IdToken';

export const getAccessToken = getOr('NO_ACCESS_TOKEN_AVAILABLE', 'tokenHandling.accessToken');

export const getIdToken = getOr<null | RIOIdToken | VWIdToken>(null, 'tokenHandling.idToken');

export const READ_ONLY_GLOBAL_ADMIN_ROLE = 'B2BX_WEBSCM_WEBSCMGLOBALADMINREADONLY';
export const READ_ONLY_PLANT_ADMIN_ROLE = 'B2BX_WEBSCM_WEBSCMPLANTADMINREADONLY';
export const READ_ONLY_COUNTRY_ADMIN_ROLE = 'B2BX_WEBSCM_WEBSCMCOUNTRYADMINREADONLY';
export const READ_ONLY_ADMIN_ROLES = [
    READ_ONLY_GLOBAL_ADMIN_ROLE,
    READ_ONLY_PLANT_ADMIN_ROLE,
    READ_ONLY_COUNTRY_ADMIN_ROLE,
];
export const ADMIN_ROLE = 'B2BX_WEBSCM_WEBSCMGLOBALADMIN';
export const SUPPLIER_ROLE = 'B2BX_WEBSCM_WEBSCMSUPPLIER';
export const DEFAULT_ROLE = 'B2BX_WEBSCM_SOLUTION';
const RIO_EU_TEST_TRIANGULAR_PROCESS_TEST_ACCOUNT_IDS = [
    '7e4c4872-8538-4e15-a474-5f8ef13b28b4',
    '9eb19508-3bb1-4414-a63b-36700b637cde',
];

const tokenContainsAdminAndGlobalReadOnlyAdmin = (idToken: VWIdToken) => {
    return idToken.groups.includes(ADMIN_ROLE) && idToken.groups.includes(READ_ONLY_GLOBAL_ADMIN_ROLE);
};

export const isReadOnlyAdmin = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken !== null && isVWIdToken(idToken) && !tokenContainsAdminAndGlobalReadOnlyAdmin(idToken)) {
        return READ_ONLY_ADMIN_ROLES.some((roAdminRole) => idToken.groups.includes(roAdminRole));
    }
    return false;
};

export const isAdmin = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken == null) {
        return false;
    }
    if (isRioIdToken(idToken)) {
        const queryParams = new URLSearchParams(window.location.search.split('?')[1]);
        return queryParams.get('role') !== 'supplier';
    }
    if (isVWIdToken(idToken)) {
        return idToken.groups.some((group) => group === ADMIN_ROLE);
    }
    return false;
};

export const isSupplier = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken == null) {
        return false;
    }
    if (isRioIdToken(idToken)) {
        const queryParams = new URLSearchParams(window.location.search.split('?')[1]);
        return (
            queryParams.get('role') === 'supplier' ||
            RIO_EU_TEST_TRIANGULAR_PROCESS_TEST_ACCOUNT_IDS.includes(idToken.account)
        );
    }
    if (isVWIdToken(idToken)) {
        return idToken.groups.some((group) => group === SUPPLIER_ROLE);
    }
    return false;
};

export const getDunsNumberFromToken = (state: any): string | undefined => {
    const idToken = getIdToken(state);
    if (idToken !== null && isVWIdToken(idToken)) {
        return idToken.duns_number;
    }
    return undefined;
};
