// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const userInfoCodec = t.type({
    duns_number: t.string,
});

export const companyInfoCodec = t.type({
    company_name: t.string,
});
