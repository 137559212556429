import { Children, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { dispatchProposalApi } from '../../../api/dispatchProposal/dispatchProposalApi';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { courierOrderIntentSlice } from '../../../reducers/courierOrderIntent/CourierOrderIntentReducer';
import { getOverviewDateRangeISO } from '../../../selectors/dispatchProposals/DispatchProposals.selector';

interface WizardProps<State> {
    onSubmit: (values: State) => void;
    onNext: (values: State) => void;
    onPrevious: (values: State) => void;
    children: ReactNode;
    currentPage: number;
}
interface StepProps {
    children: ReactNode;
    onBeforeNextStep?: (data?: any) => void;
}

const Step = (props: StepProps) => <>{props.children}</>;
const Wizard = (props: WizardProps<any> & StepProps) => {
    const { onSubmit, onNext, onPrevious, children, currentPage } = props;
    const totalPages = Children.count(children);
    const childrenArray = Children.toArray(children);
    const currentChild = childrenArray[currentPage] as ReactElement<StepProps>;

    const isLastPage = currentPage === totalPages - 1;
    const secondToLastPage = currentPage === totalPages - 2;
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const dispatchProposalsDateRange = useAppSelector(getOverviewDateRangeISO);

    const closeDialog = () => {
        dispatch(courierOrderIntentSlice.actions.stopCourierOrderIntentCommissioning());
        dispatch(
            dispatchProposalApi.endpoints?.getDispatchProposals?.initiate(
                {
                    dunsNumber,
                    cutoffDateFrom: dispatchProposalsDateRange.from,
                    cutoffDateTo: dispatchProposalsDateRange.to,
                },
                { forceRefetch: true },
            ),
        );
    };

    return (
        <form
            className=''
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e);
            }}
        >
            <div className='modal-body overflow-y-scroll height-75vh'>
                <div className='padding-20'>
                    <div className='display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct margin-left-2pct margin-right-2pct min-height-500'>
                        {currentChild}
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <div className='pull-left'>
                    {currentPage > 0 && !isLastPage && (
                        <button type='button' className='btn btn-default' onClick={onPrevious}>
                            <span className='rioglyph rioglyph-chevron-left' />
                            <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.back'} />
                        </button>
                    )}
                </div>
                <div className={'pull-right btn-toolbar'}>
                    {!isLastPage && (
                        <button type='button' className='btn btn-default' onClick={closeDialog}>
                            <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.cancel'} />
                        </button>
                    )}

                    <button
                        type='submit'
                        className='btn btn-primary'
                        onClick={(e) => {
                            e.preventDefault();
                            if (isLastPage) {
                                onSubmit(e);
                                closeDialog();
                            } else {
                                onNext(e);
                            }
                        }}
                    >
                        {secondToLastPage && (
                            <>
                                <span className='rioglyph rioglyph-van' />
                                <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.bindinglyOrder'} />
                            </>
                        )}
                        {isLastPage && <FormattedMessage id='webedi.common.close' />}

                        {!(isLastPage || secondToLastPage) && (
                            <>
                                <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.next'} />
                                <span className='rioglyph rioglyph-chevron-right padding-left-5' />
                            </>
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

Wizard.Step = Step;

export { Wizard, Step };
