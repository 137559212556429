import { FormattedMessage } from 'react-intl';
import {
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    TooManyPackagingItemsInOneLoadingUnitProblem,
    isTooManyPackagingItemsInOneLoadingUnitProblem,
} from '../../../../domain/dispatchProposal.types';
import { DispatchProposalProblemBannerFunctions } from '../DispatchProposalProblemBanner';
import { BannerIcon, colorByProblemLevel } from '../DispatchProposalProblemBannerFormatters';

const filterTooManyPackagingItemsInOneLoadingUnitProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isTooManyPackagingItemsInOneLoadingUnitProblem);

const useMapToTooManyPackagingItemsInOneLoadingUnitProblem = (problemBases: DispatchProposalProblemBase[]) => {
    const problems = problemBases as TooManyPackagingItemsInOneLoadingUnitProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    return {
        title: <TooManyPackagingItemsInOneLoadingUnitProblemTitle entries={problems} />,
        rows: [],
        formatting: {
            testId: problems[0].type,
            horizontalDivider: undefined,
            icon: BannerIcon({ level: alertLevel }),
            buttonStyle: colorByProblemLevel(alertLevel),
            printEntries: false,
        },
    };
};

const TooManyPackagingItemsInOneLoadingUnitProblemTitle = (props: {
    entries: TooManyPackagingItemsInOneLoadingUnitProblem[];
}) => {
    const identifierCount = props.entries.map((entry) => entry.identifiers.length).reduce((acc, val) => acc + val, 0);
    const id =
        identifierCount === 1
            ? 'webedi.dispatchProposals.problemBanner.dispatchProposalTooManyPackagingItemsInOneLoadingUnit.title.one'
            : 'webedi.dispatchProposals.problemBanner.dispatchProposalTooManyPackagingItemsInOneLoadingUnit.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} values={{ numberOfPositions: identifierCount }} />
        </strong>
    );
};

export const tooManyPackagingItemsInOneLoadingUnitProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterTooManyPackagingItemsInOneLoadingUnitProblem,
    useGenerateProblemDescriptor: useMapToTooManyPackagingItemsInOneLoadingUnitProblem,
};
