// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

const consignorCodec = t.type({
    duns_number: t.string,
});

export type ApiConsignor = t.TypeOf<typeof consignorCodec>;

export const getConsignorsResponseCodec = t.type({
    items: t.array(consignorCodec),
});
