import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import DropdownSubmenu from '@rio-cloud/rio-uikit/DropdownSubmenu';
import { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { notifySuccess } from '../../../actions/NotificationUtils';
import { DispatchProposalItemKey, usePostOperationMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    ApplyPackagingTemplateToItemOperation,
    ConvertItemToKepOperation,
    DispatchProposalOperationType,
} from '../../../api/dispatchProposal/operations.types';
import { useGetPackagingTemplatesQuery } from '../../../api/packagingTemplate/packagingTemplateApi';
import {
    ArticleContent,
    ArticleSuggestion,
    DispatchProposal,
    DispatchProposalItem,
    PackagedDispatchProposalItem,
    isDispatchProposalItem,
    isLtlOrFtlTermination,
    isPackagedDispatchProposalItem,
    isTooManyPackagingItemsInOneLoadingUnitProblem,
    isUnpackagedDispatchProposalItem,
} from '../../../domain/dispatchProposal.types';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { PackagingTemplate } from '../../../reducers/deliverySchedules/types';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import { Dialog } from '../../../reducers/dispatchProposal/types';
import { Tooltip } from '../../common/Tooltip';
import { Routing } from '../../routing/Routes';
import { TableColumnConfigurationRenderProps } from './DispatchProposalItemsTable';

export const PACKAGING_TEMPLATE_LOADING_SPINNER_TEST_ID = 'PACKAGING_TEMPLATE_LOADING_SPINNER_TEST_ID';
export const THREE_DOT_MENU_TEST_ID = 'THREE_DOT_MENU_TEST_ID';
export const THREE_DOT_MENU_TITLE_TEST_ID = 'THREE_DOT_MENU_TITLE_TEST_ID';
export const THREE_DOT_MENU_PACKAGING_MENU_ITEM = 'THREE_DOT_MENU_PACKAGING_MENU_ITEM';

const getDeliveryScheduleId = (item: DispatchProposalItem | ArticleSuggestion): string => {
    if (isDispatchProposalItem(item)) {
        if (isUnpackagedDispatchProposalItem(item)) {
            return item.articleContent.deliveryScheduleId;
        } else if (isPackagedDispatchProposalItem(item)) {
            return item.articleContents[0]?.deliveryScheduleId;
        }
        throw new Error("Unknown DispatchProposalItem type, can't extract the deliveryScheduleId");
    }
    throw new Error(
        'In case of an ArticleSuggestion, the deliveryScheduleId is not available ' +
            'and the ThreeDotMenu should not be rendered',
    );
};

const isHomogeneous = (item: DispatchProposalItem | ArticleSuggestion): boolean =>
    isDispatchProposalItem(item) && isPackagedDispatchProposalItem(item) && item.articleContents.length === 1;

const filterPackagedItemsInDispatchProposal = (dispatchProposal: DispatchProposal): DispatchProposalItem[] =>
    dispatchProposal.items.filter((dispatchProposalItem) => isPackagedDispatchProposalItem(dispatchProposalItem));

const filterHomogeneousAndFromSameShipment = (
    dispatchProposal: DispatchProposal,
    selectedItem: DispatchProposalItem | ArticleSuggestion,
): DispatchProposalItem[] =>
    dispatchProposal.items.filter(
        (dispatchProposalItem) =>
            isHomogeneous(dispatchProposalItem) &&
            isDispatchProposalItem(selectedItem) &&
            isDispatchProposalItem(dispatchProposalItem) &&
            dispatchProposalItem.identifier.shipmentId === selectedItem.identifier.shipmentId,
    );

const isPackageTogetherDisabled = (
    dispatchProposal: DispatchProposal,
    selectedItem: DispatchProposalItem | ArticleSuggestion,
): boolean =>
    !isHomogeneous(selectedItem) ||
    filterHomogeneousAndFromSameShipment(dispatchProposal, selectedItem).length < 2 ||
    filterPackagedItemsInDispatchProposal(dispatchProposal).length < 2 ||
    dispatchProposal.termination.transportConcept === TransportConcept.KEP;

export const ThreeDotMenu = (props: TableColumnConfigurationRenderProps) => {
    const dunsNumber = useDunsNumberFromPath()!;
    const deliveryScheduleId = getDeliveryScheduleId(props.item);
    const { data, isSuccess, isLoading } = useGetPackagingTemplatesQuery({ dunsNumber, deliveryScheduleId });
    const [postOperationMutationCall] = usePostOperationMutation();
    const dispatchProposal = props.dispatchProposal;
    const dispatchProposalItem = props.item as DispatchProposalItem;
    const intl = useIntl();

    const dispatch = useAppDispatch();

    const packageTogetherMenuItem: MenuItemProps = {
        value: <FormattedMessage id={'webedi.label.packageTogether'} />,
        disabled: isPackageTogetherDisabled(dispatchProposal, props.item),
        onSelect: () => {
            dispatch(dispatchProposalsSlice.actions.setOverlayDialogue({ dialog: Dialog.PACKAGE_TOGETHER }));
            dispatch(dispatchProposalsSlice.actions.setSelectedDispatchProposalToPackage(dispatchProposal));
            dispatch(
                dispatchProposalsSlice.actions.setSelectedDispatchProposalItemToPackage(
                    props.item as PackagedDispatchProposalItem,
                ),
            );
        },
    };

    const applyPackagingTemplate = (packagingTemplate: PackagingTemplate, item: DispatchProposalItem) => {
        let itemIdentifier: DispatchProposalItemKey;

        if (isPackagedDispatchProposalItem(item)) {
            itemIdentifier = {
                id: item.identifier.id,
                shipmentId: item.identifier.shipmentId,
            };
        } else {
            itemIdentifier = {
                shipmentId: item.identifier.shipmentId,
                deliveryNoteNumber: item.identifier.deliveryNoteNumber,
                deliveryNotePosition: item.identifier.deliveryNotePosition,
            };
        }

        const operation: ApplyPackagingTemplateToItemOperation = {
            dispatchProposalId: dispatchProposal.id,
            dunsNumber,
            parameters: { itemIdentifier, packagingTemplateId: packagingTemplate.id },
            type: DispatchProposalOperationType.APPLY_PACKAGING_TEMPLATE_TO_ITEM,
        };
        postOperationMutationCall(operation)
            .unwrap()
            .then(() => {
                notifySuccess('webedi.label.success.save');
            })
            .catch(() => {
                // do nothing; the error is already presented by the rtkGlobalErrorHandler
            });
    };

    const calculatePackagingTemplates = (): MenuItemProps[] => {
        if (isLoading) {
            return [
                {
                    value: <Spinner data-testid={PACKAGING_TEMPLATE_LOADING_SPINNER_TEST_ID} />,
                },
            ];
        } else if (!isSuccess) {
            return [];
        } else {
            return data.map((packagingTemplate) => {
                return {
                    value: packagingTemplate.name,
                    onSelect: () => applyPackagingTemplate(packagingTemplate, dispatchProposalItem),
                    disabled:
                        isPackagedDispatchProposalItem(dispatchProposalItem) && !isHomogeneous(dispatchProposalItem),
                };
            });
        }
    };
    const packagingTemplates = calculatePackagingTemplates();

    const openArticleMasterDataInNewTab = (item: ArticleContent) => {
        window.open(
            `${Routing.webScm}/${dunsNumber}${Routing.deliverySchedules}/${item.deliveryScheduleId}${Routing.metadata}`,
            '_blank',
        );
    };

    const articleMasterDataSubMenuItem = (item: ArticleContent): MenuItemProps => {
        return {
            value: item.articleNumberBuyer,
            onSelect: () => openArticleMasterDataInNewTab(item),
        };
    };

    const submenuHeader =
        packagingTemplates.length > 0
            ? [
                  {
                      value: <FormattedMessage id={'webedi.label.applyPackaging'} />,
                      header: true,
                  },
              ]
            : [];

    const isUnpackaged = isUnpackagedDispatchProposalItem(dispatchProposalItem);
    const tooManyPackagingItemsInOneLoadingUnit = props.problems.some(isTooManyPackagingItemsInOneLoadingUnitProblem);

    const subMenuArticleMasterDataIfSingleItem =
        isUnpackaged || (tooManyPackagingItemsInOneLoadingUnit && dispatchProposalItem.articleContents.length === 1)
            ? [
                  { divider: true },
                  {
                      value: <FormattedMessage id={'webedi.packaging.addPackagingTemplate'} />,
                      onSelect: () =>
                          openArticleMasterDataInNewTab(
                              isUnpackaged
                                  ? dispatchProposalItem.articleContent
                                  : dispatchProposalItem.articleContents[0],
                          ),
                  },
              ]
            : [];

    const convertDispatchProposalItemToKep = (item: DispatchProposalItem) => {
        const operation: ConvertItemToKepOperation = {
            type: DispatchProposalOperationType.CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP,
            dunsNumber,
            dispatchProposalId: dispatchProposal.id,
            parameters: {
                itemIdentifier: item.identifier,
            },
        };

        postOperationMutationCall(operation)
            .unwrap()
            .then(() => {
                notifySuccess('webedi.dispatchProposal.convertItemToKep.success');
            });
    };

    const ConvertToKepIconAndText = () => (
        <>
            <span className={'rioglyph rioglyph-van'} />
            &nbsp;
            <FormattedMessage id={'webedi.dispatchProposal.convertItemToKep.title'} />
        </>
    );

    const convertToKepAction = (multipleArticlesPackagedTogether: boolean) => {
        if (multipleArticlesPackagedTogether) {
            return (
                <Tooltip
                    text={intl.formatMessage({ id: 'webedi.dispatchProposal.convertItemToKep.notPossible.tooltip' })}
                    placement={'bottom'}
                >
                    <span>
                        <ConvertToKepIconAndText />
                    </span>
                </Tooltip>
            );
        }
        return <ConvertToKepIconAndText />;
    };

    const isMultipleArticlesPackagedTogether = !isUnpackaged && dispatchProposalItem.articleContents.length > 1;
    const dropDownMenuConvertItemToKep = isLtlOrFtlTermination(dispatchProposal)
        ? [
              {
                  value: convertToKepAction(isMultipleArticlesPackagedTogether),
                  onSelect: () => convertDispatchProposalItemToKep(dispatchProposalItem),
                  disabled:
                      !dispatchProposal.operations.convertDispatchProposalItemToKep.allowed ||
                      isMultipleArticlesPackagedTogether,
              } as MenuItemProps,
          ]
        : [];

    const subMenuArticleMasterDataItems = (
        isUnpackaged ? [dispatchProposalItem.articleContent] : dispatchProposalItem.articleContents
    ).map(articleMasterDataSubMenuItem);

    const packagingProblemsFound = isUnpackaged || tooManyPackagingItemsInOneLoadingUnit;
    const optionalIndicatorButton = packagingProblemsFound ? 'indicated-button' : '';
    const errorTextOrEmpty = packagingProblemsFound ? 'text-color-danger' : '';
    const parcelOrError = packagingProblemsFound ? 'text-color-danger rioglyph-error-sign' : 'rioglyph-parcel';
    return (
        <div className={optionalIndicatorButton}>
            <ButtonDropdown
                data-testid={THREE_DOT_MENU_TEST_ID}
                key={dispatchProposal.id + props.rowIndex}
                iconOnly={true}
                dropdownClassName={'width-300'}
                bsStyle={'link' as BUTTON_STYLE}
                title={
                    <span
                        className={`rioglyph rioglyph-option-vertical ${errorTextOrEmpty}`}
                        data-testid={THREE_DOT_MENU_TITLE_TEST_ID}
                    />
                }
                items={[
                    {
                        value: (
                            <DropdownSubmenu
                                title={
                                    <span className={errorTextOrEmpty}>
                                        <span
                                            className={`rioglyph ${parcelOrError}`}
                                            data-testid={THREE_DOT_MENU_PACKAGING_MENU_ITEM}
                                        />
                                        &nbsp;
                                        <FormattedMessage id={'webedi.packaging'} />
                                    </span>
                                }
                                items={[
                                    packageTogetherMenuItem,
                                    ...submenuHeader,
                                    ...packagingTemplates,
                                    ...subMenuArticleMasterDataIfSingleItem,
                                ]}
                            />
                        ),
                    },
                    {
                        value: (
                            <DropdownSubmenu
                                title={
                                    <>
                                        <span className={'rioglyph rioglyph-document'} />
                                        &nbsp;
                                        <FormattedMessage id={'webedi.deliverySchedule.masterData.title'} />
                                    </>
                                }
                                items={subMenuArticleMasterDataItems}
                            />
                        ),
                    },
                    ...dropDownMenuConvertItemToKep,
                ]}
            />
            {packagingProblemsFound && (
                <span className={'badge badge-danger badge-indicator margin-right-10  z-index-3'} />
            )}
        </div>
    );
};
